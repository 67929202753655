@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	body {
		@apply bg-gray-50;
	}

	h1,
	h2,
	h3,
	label {
		@apply first:mt-0;
		@apply from-neutral-900;
	}

	h1 {
		@apply font-light text-2xl mb-1;
	}

	h2 {
		@apply text-xl mt-5 mb-2 font-light;
	}

	h3 {
		@apply text-lg mt-4 font-normal;
	}

	label {
		@apply text-sm font-bold;
	}

	p,
	li,
	span {
		@apply font-light;
	}

	a {
		@apply text-blue-500;
	}
	/* Custom loading dots animation */
	@keyframes dotAnimation {
		0% {
			content: '   '; /* Three spaces to compensate for the absence of dots */
		}
		25% {
			content: '.  '; /* One dot and two spaces */
		}
		50% {
			content: '.. '; /* Two dots and one space */
		}
		75% {
			content: '...'; /* Three dots, no spaces needed */
		}
	}

	.loading-dots::after {
		content: '   '; /* Start with three spaces */
		animation: dotAnimation 2s infinite step-start;
	}
}
